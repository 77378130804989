import React from "react";
interface IIconProps {
  className?: string;
  size?: number;
}
export const MenuIcon: React.FC<IIconProps> = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18" fill="none" className="">
      <path
        d="M16.8746 1.9249H1.12458C1.03619 1.9249 0.963867 1.99722 0.963867 2.08561V3.37132C0.963867 3.45972 1.03619 3.53204 1.12458 3.53204H16.8746C16.963 3.53204 17.0353 3.45972 17.0353 3.37132V2.08561C17.0353 1.99722 16.963 1.9249 16.8746 1.9249ZM16.8746 14.4606H1.12458C1.03619 14.4606 0.963867 14.5329 0.963867 14.6213V15.907C0.963867 15.9954 1.03619 16.0678 1.12458 16.0678H16.8746C16.963 16.0678 17.0353 15.9954 17.0353 15.907V14.6213C17.0353 14.5329 16.963 14.4606 16.8746 14.4606ZM16.8746 8.19275H1.12458C1.03619 8.19275 0.963867 8.26508 0.963867 8.35347V9.63918C0.963867 9.72758 1.03619 9.7999 1.12458 9.7999H16.8746C16.963 9.7999 17.0353 9.72758 17.0353 9.63918V8.35347C17.0353 8.26508 16.963 8.19275 16.8746 8.19275Z"
        fill="black"
        fillOpacity={0.85}
      />
    </svg>
  );
};

export const WAIcon = ({ size }: IIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 32" fill="none" style={{ width: size, height: size }}>
    <g clipPath="url(#clip0_179582_20812)">
      <path
        d="M21.6779 19.2681C21.3141 19.0744 19.5018 18.1541 19.1647 18.0295C18.8276 17.898 18.5806 17.8358 18.337 18.2233C18.09 18.6073 17.3891 19.4619 17.1688 19.7214C16.9552 19.9774 16.7383 20.0085 16.3745 19.8182C14.2117 18.6973 12.7933 17.8185 11.3681 15.2824C10.991 14.6077 11.7453 14.6562 12.4495 13.1996C12.5697 12.9436 12.5096 12.7256 12.4161 12.5319C12.3227 12.3381 11.5884 10.4629 11.2813 9.69826C10.9843 8.9544 10.6772 9.05819 10.4536 9.04435C10.24 9.03051 9.99638 9.03051 9.7494 9.03051C9.50241 9.03051 9.10524 9.12739 8.76815 9.50451C8.43105 9.88855 7.47984 10.8123 7.47984 12.6875C7.47984 14.5628 8.79818 16.3792 8.97841 16.6352C9.16532 16.8912 11.5717 20.7386 15.2664 22.3958C17.6027 23.4407 18.5172 23.5306 19.6854 23.3507C20.3963 23.24 21.8615 22.4304 22.1652 21.5343C22.4689 20.6417 22.4689 19.8771 22.3788 19.7179C22.2887 19.5484 22.0417 19.4515 21.6779 19.2681Z"
        fill="black"
        fillOpacity={0.85}
      />
      <path
        d="M28.7435 10.2291C27.9892 8.37118 26.9078 6.70354 25.5294 5.27118C24.1606 3.8471 22.5357 2.71514 20.7466 1.93937C18.9109 1.14015 16.9618 0.735352 14.9526 0.735352H14.8858C12.8632 0.745731 10.9041 1.16091 9.06173 1.97743C7.288 2.76115 5.6783 3.89513 4.32236 5.31615C2.95729 6.74506 1.88593 8.40578 1.14498 10.2568C0.377337 12.1735 -0.00982341 14.2114 0.00018935 16.308C0.0115146 18.7108 0.559875 21.0781 1.60223 23.2242V28.4831C1.60223 28.9052 1.76398 29.31 2.05191 29.6085C2.33983 29.907 2.73034 30.0746 3.13752 30.0746H8.21399C10.2843 31.1552 12.568 31.7236 14.8858 31.7354H14.9559C16.9551 31.7354 18.8943 31.334 20.7199 30.5486C22.4999 29.7821 24.1188 28.6633 25.486 27.2549C26.8644 25.8398 27.9491 24.186 28.7068 22.3419C29.4944 20.4321 29.8949 18.4012 29.905 16.3045C29.915 14.1975 29.5211 12.1528 28.7435 10.2291ZM23.7004 25.3831C21.3607 27.7842 18.2568 29.1059 14.9526 29.1059H14.8958C12.8833 29.0955 10.8841 28.5765 9.11847 27.6009L8.83812 27.4452H4.1388V22.5737L3.9886 22.2831C3.04741 20.4529 2.54677 18.3804 2.53675 16.2942C2.5234 12.8447 3.79502 9.60633 6.128 7.16716C8.45763 4.72799 11.5716 3.37865 14.8992 3.36482H14.9559C16.6247 3.36482 18.2434 3.70042 19.7687 4.3647C21.2573 5.01169 22.5923 5.94238 23.7404 7.13256C24.8852 8.31928 25.7864 9.70667 26.4105 11.2497C27.058 12.8482 27.3817 14.5435 27.3751 16.2942C27.355 19.7402 26.05 22.9682 23.7004 25.3831Z"
        fill="black"
        fillOpacity={0.85}
      />
    </g>
    <defs>
      <clipPath id="clip0_179582_20812">
        <rect width={29.9048} height={31} fill="white" transform="translate(0 0.735352)" />
      </clipPath>
    </defs>
  </svg>
);

export const IGIcon = ({ size }: IIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 31 32" fill="none">
    <path
      d="M15.3177 12.2C13.1741 12.2 11.4248 14.0134 11.4248 16.2355C11.4248 18.4575 13.1741 20.2709 15.3177 20.2709C17.4613 20.2709 19.2106 18.4575 19.2106 16.2355C19.2106 14.0134 17.4613 12.2 15.3177 12.2ZM26.9934 16.2355C26.9934 14.5644 27.008 12.9084 26.9175 11.2404C26.827 9.30285 26.4006 7.58332 25.0338 6.16653C23.6642 4.7467 22.0083 4.30774 20.1393 4.21389C18.5272 4.12004 16.9298 4.13518 15.3206 4.13518C13.7086 4.13518 12.1111 4.12004 10.502 4.21389C8.63294 4.30774 6.97416 4.74973 5.60742 6.16653C4.23776 7.58635 3.8143 9.30285 3.72377 11.2404C3.63324 12.9114 3.64784 14.5674 3.64784 16.2355C3.64784 17.9035 3.63324 19.5625 3.72377 21.2306C3.8143 23.1681 4.24068 24.8876 5.60742 26.3044C6.97708 27.7242 8.63294 28.1632 10.502 28.257C12.114 28.3509 13.7115 28.3358 15.3206 28.3358C16.9327 28.3358 18.5301 28.3509 20.1393 28.257C22.0083 28.1632 23.6671 27.7212 25.0338 26.3044C26.4035 24.8846 26.827 23.1681 26.9175 21.2306C27.0109 19.5625 26.9934 17.9066 26.9934 16.2355ZM15.3177 22.4446C12.0031 22.4446 9.32799 19.6715 9.32799 16.2355C9.32799 12.7994 12.0031 10.0264 15.3177 10.0264C18.6323 10.0264 21.3074 12.7994 21.3074 16.2355C21.3074 19.6715 18.6323 22.4446 15.3177 22.4446ZM21.5527 11.2222C20.7788 11.2222 20.1539 10.5743 20.1539 9.77209C20.1539 8.96985 20.7788 8.32199 21.5527 8.32199C22.3266 8.32199 22.9516 8.96985 22.9516 9.77209C22.9518 9.96259 22.9158 10.1513 22.8456 10.3273C22.7754 10.5033 22.6723 10.6633 22.5424 10.798C22.4125 10.9327 22.2582 11.0395 22.0883 11.1123C21.9185 11.1851 21.7365 11.2224 21.5527 11.2222Z"
      fill="black"
      fillOpacity={0.85}
    />
  </svg>
);

export const YoutubeIcon = ({ size }: IIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 31 32" fill="none">
    <path
      d="M28.2197 9.69932C28.0707 9.12424 27.7787 8.5998 27.3728 8.17849C26.9668 7.75718 26.4613 7.45377 25.9067 7.29863C23.8654 6.72949 15.6825 6.72949 15.6825 6.72949C15.6825 6.72949 7.49953 6.72949 5.45818 7.29561C4.90337 7.45024 4.39757 7.75348 3.99158 8.17487C3.58559 8.59626 3.2937 9.12096 3.14523 9.69629C2.59912 11.8154 2.59912 16.2354 2.59912 16.2354C2.59912 16.2354 2.59912 20.6553 3.14523 22.7714C3.44603 23.9399 4.33383 24.8603 5.45818 25.1721C7.49953 25.7412 15.6825 25.7412 15.6825 25.7412C15.6825 25.7412 23.8654 25.7412 25.9067 25.1721C27.034 24.8603 27.9189 23.9399 28.2197 22.7714C28.7658 20.6553 28.7658 16.2354 28.7658 16.2354C28.7658 16.2354 28.7658 11.8154 28.2197 9.69932ZM13.0833 20.292V12.1787L19.8586 16.2051L13.0833 20.292Z"
      fill="black"
      fillOpacity={0.85}
    />
  </svg>
);

export const LinkedinIcon = ({ size }: IIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 30 32" fill="none">
    <path
      d="M25.7948 4.12598H4.30073C3.78383 4.12598 3.36621 4.55889 3.36621 5.09473V27.376C3.36621 27.9118 3.78383 28.3447 4.30073 28.3447H25.7948C26.3117 28.3447 26.7293 27.9118 26.7293 27.376V5.09473C26.7293 4.55889 26.3117 4.12598 25.7948 4.12598ZM10.2963 24.7634H6.82979V13.205H10.2963V24.7634ZM8.5645 11.6247C8.16711 11.6247 7.77865 11.5026 7.44823 11.2737C7.11782 11.0448 6.86029 10.7195 6.70822 10.339C6.55614 9.95837 6.51635 9.53958 6.59388 9.13556C6.67141 8.73153 6.86277 8.36041 7.14376 8.06912C7.42476 7.77784 7.78277 7.57947 8.17252 7.4991C8.56227 7.41874 8.96626 7.45998 9.3334 7.61763C9.70053 7.77527 10.0143 8.04223 10.2351 8.38475C10.4559 8.72726 10.5737 9.12995 10.5737 9.54189C10.5708 10.6923 9.67133 11.6247 8.5645 11.6247ZM23.2745 24.7634H19.8109V19.1416C19.8109 17.8005 19.7875 16.0779 18.009 16.0779C16.2072 16.0779 15.9297 17.5371 15.9297 19.0447V24.7634H12.4691V13.205H15.7925V14.7853H15.8392C16.3006 13.8771 17.4308 12.9174 19.1188 12.9174C22.6291 12.9174 23.2745 15.312 23.2745 18.4241V24.7634Z"
      fill="black"
      fillOpacity={0.85}
    />
  </svg>
);

export const PlayIcon = ({ size }: IIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 10 12" fill="none">
    <path d="M10 6.21429L0.25 11.8435L0.25 0.585129L10 6.21429Z" fill="#1D6758" />
  </svg>
);

export const RightArrowIcon = ({ size }: IIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 23" fill="none">
    <path
      d="M19.7658 10.9054L10.4895 2.8543C10.4183 2.79292 10.3274 2.75854 10.2317 2.75854H8.05868C7.87698 2.75854 7.7935 2.98444 7.931 3.10229L16.5297 10.5666H2.16091C2.05287 10.5666 1.96448 10.655 1.96448 10.763V12.2362C1.96448 12.3443 2.05287 12.4327 2.16091 12.4327H16.5272L7.92854 19.8969C7.79104 20.0173 7.87452 20.2407 8.05622 20.2407H10.3029C10.3495 20.2407 10.3962 20.2235 10.4305 20.1916L19.7658 12.0938C19.8508 12.0199 19.919 11.9286 19.9657 11.8261C20.0124 11.7236 20.0366 11.6123 20.0366 11.4996C20.0366 11.387 20.0124 11.2756 19.9657 11.1731C19.919 11.0706 19.8508 10.9793 19.7658 10.9054Z"
      fill="#1D6758"
    />
  </svg>
);
