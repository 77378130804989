import { useLocation, useSearchParams } from "react-router-dom";

export const useParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlSearchParams = useLocation();

  const getSearchParamsObj = () => {
    try {
      const paramURLArr = urlSearchParams.search
        .slice(1, urlSearchParams.search.length)
        .split("&")
        .map((param) => {
          return param.split("=");
        })
        .map((param: any) => {
          if (param[1].indexOf("%2C") !== -1) {
            param[1] = param[1].split("%2C");
          }
          return param;
        });

      const paramObj: any = {};
      paramURLArr.forEach((paramURLARR) => {
        paramObj[paramURLARR[0]] = paramURLARR[1];
      });

      return paramObj;
    } catch (error) {
      return {};
    }
  };

  const handleSetParam = (newValueParam: any) => {
    let currentSearchParams = getSearchParamsObj();
    currentSearchParams = { ...currentSearchParams, ...newValueParam };
    setSearchParams(currentSearchParams);
  };

  const getSearchParamValue = (key: string) => {
    return searchParams.get(key);
  };

  return { handleSetParam, getSearchParamsObj, getSearchParamValue };
};
