import React from "react";
import Layout from "../../components/organism/Layout/Layout";
import { gql, request } from "graphql-request";
import { IBlog } from "../../entities/Blog/Blog";
import { useQuery } from "react-query";
import { parseDate } from "../../lib/utils";
import { RichText } from "@graphcms/rich-text-react-renderer";
import { useLocation } from "react-router-dom";
import Typography from "../../components/atoms/Typography/Typography";

export default function Article() {
  const location = useLocation();
  const currentURL = location.pathname;

  const segments = currentURL.split("/");
  const slug = segments[segments.length - 1];

  const getArticle = async (): Promise<IBlog> => {
    const query = gql`
      query getArticle {
        article(where: { slug: "${slug}" }) {
          createdBy {
            name
          }
          slug
          title
          updatedAt
          content {
            raw
          }
          display {
            height
            width
            url
            fileName
          }
        }
      }
    `;
    type TResult = {
      article: IBlog;
    };

    let result: TResult = await request(process.env.REACT_APP_API_HYGRAPH as string, query);
    return result.article;
  };

  const { data: article, isLoading } = useQuery({ queryKey: ["article" + slug], queryFn: getArticle });
  return (
    <Layout>
      {!isLoading && <div className="py-4  ">
        <header>
          <div className=" text-4xl font-semibold py-2">{article?.title}</div>
          <div className="pb-10  border-b dark:border-white border-black text-base dark:opacity-60">
            Written by {article?.createdBy.name} at {article?.updatedAt && parseDate(article?.updatedAt)}
          </div>
        </header>
        <div className="">
          <article className=" col-span-10 lg:col-span-7 ">
            <div className="py-10 ">
              <RichText
                content={article?.content.raw}
                renderers={{
                  h1: ({ children }) => <Typography variant="h1">{children}</Typography>,
                  h2: ({ children }) => <Typography variant="h2">{children}</Typography>,
                  h3: ({ children }) => <Typography variant="h3">{children}</Typography>,
                  h4: ({ children }) => <Typography variant="h4">{children}</Typography>,
                  h5: ({ children }) => <Typography variant="h5">{children}</Typography>,
                  p: ({ children }) => <Typography variant="p16">{children}</Typography>,
                  bold: ({ children }) => <strong>{children}</strong>,
                }}
              />
            </div>
          </article>
        </div>
      </div>}

    </Layout>
  );
}
