import { useSearchParams } from "react-router-dom";
import { cn } from "../../../lib/utils";

import { useParams } from "../../../hooks/useParams";

type TTab = {
  title: string;
  param: string;
};
interface ITab {
  param: string;
  tabs: TTab[];
}

export default function Tab({ tabs, param }: ITab) {
  const [searchParams] = useSearchParams();

  const { handleSetParam } = useParams();

  const defaultParams = () => {
    if (searchParams.get(param) == null) {
      return tabs[0].param;
    }
    if (tabs.filter((tab) => tab.param === searchParams.get(param)).length < 1) {
      return tabs[0].param;
    }
    return searchParams.get(param);
  };

  return (
    <div>
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        {tabs.map((tab, key) => {
          return (
            <li className="mr-2" key={key}>
              <div
                onClick={() => handleSetParam({ [param]: tab.param })}
                className={cn(" cursor-pointer inline-block px-4 py-3  rounded-lg active", defaultParams() === tab.param ? "text-white bg-secondary" : "hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white")}
                aria-current="page"
              >
                {tab.title}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
