import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { MenuIcon } from "../../atoms/Icon";
import { checkScreen } from "../../../lib/utils";
import { ScreenSize } from "../../../constant/screenSize";

export type TMenu = {
  label: string;
  value: string;
  highlight?: boolean;
};

export interface IMenuProps {
  listMenu: TMenu[];
  className?: string;
}

const Menu: React.FC<IMenuProps> = ({ listMenu, className }) => {
  const [menuMobileVisible, setMenuMobileVisible] = useState<boolean>(false);

  const location = useLocation();

  const handleClickMenu = () => {
    menuMobileVisible ? setMenuMobileVisible(false) : setMenuMobileVisible(true);
  };

  const isScreenLg = checkScreen(ScreenSize.lg);

  if (isScreenLg) {
    return (
      <div className={clsx("text-[20px] leading-[60px] text-secondary flex  gap-10 font-bold ", className)}>
        {listMenu.map((item, index) => {
          if (item.highlight) {
            return (
              <Link to={item.value} key={index} className={clsx(" text-secondary border-secondary border-2  px-4 rounded-md hover:bg-primary hover:text-white", location.pathname === item.value && "bg-primary text-white")}>
                {item.label}
              </Link>
            );
          }
          return (
            <Link to={item.value} key={index} className={clsx("hover:text-primary", location.pathname === item.value && "text-primary")}>
              {item.label}
            </Link>
          );
        })}
      </div>
    );
  }

  return (
    <div className={clsx("lg:hidden")} onClick={handleClickMenu}>
      <MenuIcon />
      {menuMobileVisible && (
        <div className=" rounded-b-3xl shadow-lg lg:hidden fixed top-14 left-0 w-full pl-5 py-6 bg-white  flex flex-col gap-2">
          {listMenu.map((item, index) => {
            return (
              <Link to={item.value} key={index} className=" text-primary font-medium text-base leading-normal">
                {item.label}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Menu;
