import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages";
import Services from "./pages/Services/Services";
import ContactUs from "./pages/ContactUs/ContactUs";
import Solutions from "./pages/Solutions/Solutions";
import Blog from "./pages/Blog/Blog";
import { Route } from "./constant/Route";
import Website from "./pages/Services/website/Website";
import Marketing from "./pages/Services/marketing/Marketing";
import Software from "./pages/Services/software/Software";
import Article from "./pages/Blog/Article";
import ClientsFeedback from "./pages/ClientsFeedback/ClientsFeedback";

export const router = createBrowserRouter([
  {
    path: Route.home,
    element: <Home />,
  },
  {
    path: Route.clients,
    element: <ClientsFeedback />,
  },

  {
    path: Route.services,
    element: <Services />,
  },
  {
    path: Route["services-website"],
    element: <Website />,
  },
  {
    path: Route["services-marketing"],
    element: <Marketing />,
  },
  {
    path: Route["services-software-dev"],
    element: <Software />,
  },

  {
    path: Route.contactUs,
    element: <ContactUs />,
  },
  {
    path: Route.solutions,
    element: <Solutions />,
  },
  {
    path: Route.blog,
    element: <Blog />,
  },
  {
    path: Route["blog-article"],
    element: <Article />,
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
