export const Route = {
  home: "/",

  clients: "/clients",

  services: "/services",
  "services-website": "/services/website",
  "services-marketing": "/services/marketing",
  "services-software-dev": "/services/software-dev",

  contactUs: "/contact-us",
  solutions: "/about-us",
  blog: "/blog",

  "blog-article": "/blog/:slug",
};
