
import Layout from '../../components/organism/Layout/Layout'
import Topic from '../../components/molecules/Topic/Topic'
import CardClient from '../../components/molecules/Card/CardClient'
import request, { gql } from 'graphql-request';
import { IClientFeedback } from '../../entities/Blog/ClientFeedback';
import { useQuery } from 'react-query';
import { url } from 'inspector';

export default function ClientsFeedback() {

    const getClientsFeedback = async (): Promise<IClientFeedback[]> => {
        const query = gql`
        query getFeedbacks {
            clientFeedbacks(first: 100) {
              namaClient
              id
              company
              feedback
              photoClient {
                url
              }
            }
          }
        `;
        type TResultClientFeedbacks = {
            clientFeedbacks: IClientFeedback[];
        };

        let result: TResultClientFeedbacks = await request(process.env.REACT_APP_API_HYGRAPH as string, query);
        return result.clientFeedbacks;
    };
    const { data: clientsFeedback, isLoading } = useQuery({ queryKey: ["clientFeedback"], queryFn: getClientsFeedback, staleTime: 10000 });

    return (
        <Layout>
            <Topic title="What our clients says"> These are testimonials from our clients who have used Bedata services</Topic>
            <div className=' flex justify-around  flex-wrap gap-4'>
                {!isLoading && clientsFeedback?.map((clientFeedback) => <CardClient key={clientFeedback.id} clientProfile={clientFeedback.photoClient.url} clientCompany={clientFeedback.company} clientName={clientFeedback.namaClient}>
                    {clientFeedback.feedback}
                </CardClient>)}
            </div>

        </Layout>
    )
}
